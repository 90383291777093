.driver-box {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr min-content 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    'driver'
    'riders'
    'join';
  background: var(--alt-primary);
  border: 1px black solid;
  margin: 3px;
  padding: 10px 40px;

  border-radius: 20px;
  max-width: 400px;
}

.driver-box .join {
  grid-area: join;
}

.driver-box .driver {
  grid-area: driver;
  color: var(--secondary);
  font-size: larger;
  color: var(--secondary);
}

.driver-box .riders {
  grid-area: riders;
  color: var(--secondary);
}
.driver-box button {
  font-size: 0.9em;
  margin: 3px;
}
@media (max-width: 480px) {
  .driver-box {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      'driver'
      'riders'
      'join';
    background: var(--alt-primary);
    border: 1px black solid;
    margin: 3px;
    padding: 10px 40px;

    border-radius: 20px;
    min-width: 100px;
    max-width: 200px;
  }
  .driver-box .riders {
    grid-area: riders;
    color: var(--secondary);
    font-size: 0.8em;
  }
}
