@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
:root {
  --primary: #3454d1;
  --secondary: #d1345b;
  --dark: #191919;
  --light: #eccbd9;
  --alt-primary: #85c7f2;
}
* {
  margin: 0;
}
.logo {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100px;
  opacity: 0.5;
  margin: 5px;
}
body {
  width: 100vw;
  margin: 0;
  font-family: 'Roboto Mono';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--dark);
  color: var(--light);
  margin-top: 20vh;
}
label {
  font-size: 3em;
  padding: 2px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
input[type='submit'] {
  margin: 10px 30px;
  border: 2px var(--light) solid;
  background: var(--light);
  color: var(--dark);
  padding: 3px 10px;
  border-radius: 10px;
  font-size: 2em;
}
button:hover,
input[type='submit']:hover {
  cursor: pointer;
  border: 2px var(--light) solid;
  background: var(--dark);
  color: var(--light);
}

input[type='text'],
select {
  outline: none;
  margin: 10px 30px;
  font-size: 2em;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}
select option {
  background: white;
  color: var(--dark);
}

h1 {
  color: var(--secondary);
  font-size: 5em;
}
.requests button {
  font-size: 1em;
}
.drivers-boxes {
  margin-top: 0.1em;
  border: 3px solid var(--primary);
  height: auto;
  width: 80vw;
  overflow-y: hidden;
  overflow-x: scroll;
  border-radius: 3px;
  padding: 10px;
  display: flex;
}
.phone-help {
  display: none;
}
@media (max-width: 480px) {
  label {
    font-size: 1.5em;
  }
  input,
  select {
    font-size: 1em;
  }
  h1 {
    font-size: 2.5em;
  }
  .drivers-boxes {
    border: 1px solid var(--primary);
    height: auto;
    max-width: 80vw;
    overflow-y: hidden;
    overflow-x: scroll;
    border-radius: 3px;
    padding: 10px;
    display: flex;
    margin-top: 5px;
  }
  .phone-help {
    display: inline;
  }
}
